// Testimonial (core/sections/_testimonial.scss) ---------------
$testimonial--padding-t__mobile: 		null;		// testimonial, top padding (mobile)
$testimonial--padding-b__mobile: 		null;		// testimonial, bottom padding (mobile)
$testimonial--padding-t__desktop: 		null;		// testimonial, top padding (desktop)
$testimonial--padding-b__desktop: 		null;		// testimonial, bottom padding (desktop)
$testimonial-header--padding__mobile: 	null;		// testimonial header, bottom padding (mobile)
$testimonial-header--padding__desktop:	null;		// testimonial header, bottom padding (desktop)
$testimonial-items--padding__mobile: 	null;		// padding between testimonial items (mobile)
$testimonial-items--padding__desktop: 	null;		// padding between testimonial items (desktop)
$testimonial-item--width: 				344px;		// testimonial item, width
$testimonial-item--inner-padding-v: 	24px;		// testimonial item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$testimonial-item--inner-padding-h: 	32px;		// testimonial item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
